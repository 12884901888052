<template>
  <b-card>
    <b-row>
      <b-col
        sm="12"
      >
        <b-overlay
          :show="loading"
          no-wrap
          :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
        />
        <div id="tinkoffId" />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import tinkoffInstallmentStoreModule from './tinkoffInstallmentStoreModule'
import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
  },
  setup() {
    const STORE_MODULE_NAME = 'tinkoff-installment'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, tinkoffInstallmentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { router } = useRouter()
    const loading = ref(false)

    if (router.currentRoute.params.id) {
      const tinkoFFscript = document.createElement('script')
      tinkoFFscript.type = 'text/javascript'
      tinkoFFscript.src = 'https://forma.tinkoff.ru/static/onlineScript.js'

      document.head.appendChild(tinkoFFscript)

      const fetchData = () => {
        loading.value = true
        return store.dispatch('tinkoff-installment/fetchData', {
          api: `https://api.prohealthfactor.ru/api/v1/htm/lkb/service/data/page/tinkoffinstallment/${router.currentRoute.params.id}`,
        })
      }

      tinkoFFscript.onload = () => {
        fetchData()
          .then(response => {
            const { data } = response

            if (data && data[0] && data[0].params) {
              window.tinkoff.create(data[0].params[0], { view: 'iframe', target: '#tinkoffId' })
            }

            loading.value = false
          })
          .catch(() => {
            loading.value = false
          })
      }
    }

    return {
      loading,
    }
  },
}
</script>
